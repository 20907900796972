.asciinema-player-theme-docs-light {
  border: solid 1px rgba(0,0,0,.05);

  --term-color-foreground: #657b83;
  --term-color-background: #fdf6e3;
  --term-color-0: #073642;
  --term-color-1: #dc322f;
  --term-color-2: #859900;
  --term-color-3: #b58900;
  --term-color-4: #268bd2;
  --term-color-5: #d33682;
  --term-color-6: #2aa198;
  --term-color-7: #eee8d5;
  --term-color-8: #002b36;
  --term-color-9: #cb4b16;
  --term-color-10: #586e75;
  --term-color-11: #657c83;
  --term-color-12: #839496;
  --term-color-13: #6c71c4;
  --term-color-14: #93a1a1;
  --term-color-15: #fdf6e3;
}

.asciinema-player-theme-docs-dark {
  border: solid 1px rgba(255,255,255,.1);

  --term-color-foreground: #f8f8f2;
  --term-color-background: var(--chakra-colors-ukblue-950);
  --term-color-0: #21222c;
  /* --term-color-1: #DD295D; */
  --term-color-1: var(--chakra-colors-orange-500);
  --term-color-2: #47D7A1;
  --term-color-3: #E7A078;
  --term-color-4: #5DB4EF;
  --term-color-5: #A44CC5;
  --term-color-6: #0397A3;
  --term-color-7: #EDEDED;
  --term-color-8: #5E6782;
  --term-color-9: #F42D66;
  --term-color-10: #4CE3AA;
  --term-color-11: #F5AA7E;
  --term-color-12: #64C1FF;
  --term-color-13: #D96CDA;
  --term-color-14: #FBFAFB;
  --term-color-15: #FBFAFB;
}

div.ap-wrapper div.ap-player {
  border-radius: 8px !important;
}
